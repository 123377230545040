<template>
  <section class="tables">
    <div class="page-header">
      <h3 class="page-title">
        Plans List
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Plans</a></li>
          <li class="breadcrumb-item active" aria-current="page">List</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-12">
        <form action="#">
          <div class="form-group d-lg-flex">
            <input type="text" class="form-control" placeholder="Search Here" v-model="searchInput">
            <button type="button" class="btn btn-primary ml-0 ml-lg-3 mt-2 mt-lg-0" @click="searchPlans" :disabled="searchInput == ''">Search</button>
          </div>
        </form>
      </div>
    </div>

    <div class="row">
      <div class="col-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Filter</h4>
            <p class="card-description">Click DONE after selecting filter</p>
            <ValidationProvider name="city_id" rules="">
              <b-form-group slot-scope="{ errors }" label-for="city_id" horizontal label="City">
                <b-form-select id="city_id" v-model="city_id" :options="cities"/>
                <p>{{ errors[0] }}</p>
              </b-form-group>
            </ValidationProvider>
            <b-form-group class="">
              <b-button type="button" @click="filterPlans" variant="success" class="mr-2">DONE</b-button>
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="col-3">
        <form action="#">
          <div class="form-group d-lg-flex">
            <input type="number" class="form-control" @change="paginateChange" placeholder="perPage" v-model="paginate">
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div style="display: flex; align-items: center; justify-content: space-between;">
              <h4 class="card-title">{{ list_title }}</h4>
              <download-excel
                class="btn btn-success"
                :data="plans"
                :fields="json_fields"
                worksheet="My Worksheet"
                name="plans.xlsx">
                Download Plans
              </download-excel>
            </div>
            <b-table :items="plans" id="table-list" responsive :busy="isBusy" :per-page="perPage" :current-page="currentPage" :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
              <template v-slot:table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
              <template v-slot:cell(created_at)="data">
                <span>{{ formatTime(data.item.created_at, "DD/MM/YYYY hh:mm:ss") }}</span>
              </template>
              <template v-slot:cell(updated_at)="data">
                <span>{{ formatTime(data.item.updated_at, "DD/MM/YYYY hh:mm:ss") }}</span>
              </template>
              <template v-slot:cell(city)="data">
                <span>{{ data.item.city.name }}</span>
              </template>
              <template v-slot:cell(geo_json)="data">
                <span v-if="data.item.geo_json != null">
                  <a :href="downloadUrl(data.item.geo_json)" target="_blank">Download</a>
                </span>
                <span v-if="!data.item.geo_json">
                  N/A
                </span>
              </template>
              <template v-slot:cell(process)="data">
                <span><b-badge pill :variant="processClass(data.item.process)">{{ data.item.process_readable }}</b-badge></span>
              </template>
              <template v-slot:cell(action)="data">
                <b-badge pill variant="outline-info">
                  <router-link :to="{ name: 'plan-view', params: {id: data.item.id} }">View</router-link>
                </b-badge>
                <b-badge pill variant="outline-info">
                  <router-link :to="{ name: 'plan-edit', params: {id: data.item.id} }">Edit</router-link>
                </b-badge>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="table-list"
              align="right">
            </b-pagination>
          </div>
        </div>
      </div>
      </div>
    <div>
    </div>
  </section>
</template>
<script>
/* eslint-disable no-debugger, no-console */

import Vue from "vue"
import SortedTablePlugin from "vue-sorted-table"
//import _ from 'lodash'

import helper from '../../util/helper.js'
import _ from "lodash";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  data: function() {
    return {
      searchInput: "",
      sortBy: 'name',
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      switch_text: "Top Viewed",
      list_title: "Plans",
      currentPage: 1,
      city_id: '',
      perPage: 0,
      paginate: 10,
      isBusy: true,
      fields: [
        { key: 'id', sortable: true },
        { key: 'planNo', sortable: true },
        { key: 'district', sortable: true },
        { key: 'city', sortable: true },
        { key: 'process', sortable: true },
        { key: 'geo_json', sortable: true },
        { key: 'created_at', sortable: true },
        { key: 'action', sortable: false }
      ],
      json_fields: {
        'ID': 'id',
        "Plan Number": "planNo",
        'District': 'district',
        'City': 'city.en_name',
      }
    };
  },
  computed: {
    rows() {
      return this.$store.state.plan.meta.total
    },
    paginationPageSize() {
      return this.$store.state.plan.meta.paginate
    },
    plans() {
      return this.$store.state.plan.plans
    },
    cities() {
      let cities = this.$store.getters['city/listCities']
      cities.unshift({value: "", text: "Select City"})
      return cities
    },
  },
  watch: {
    currentPage() {
      if(this.currentPage != this.$store.state.plan.meta.current_page)
        this.fetchPlans(this.currentPage)
    },
    searchInput() {
      if (this.searchInput == "")
        this.fetchPlans()
    }
  },
  methods: {
    filterPlans() {
      console.log(this.city_id, 'the city id')
      this.fetchPlans()
    },
    formatTime(time, format) {
      return helper.formatTime(time, format)
    },
    newClass(val) {
      if(val == "1") return "success"
      return "primary"
    },
    newText(val) {
      if(val == "1") return "new"
      return "old"
    },
    processClass(val) {
      if(val === 0) return "primary"
      if(val === 1) return "warning"
      if(val === 2) return "processing"
      if(val === 3) return "success"
      return "danger"
    },
    switchList() {
      if(this.switch_text === "Top Viewed") {
        this.switch_text =  "Plans"
        this.list_title = "Top Viewed"
      }
      else {
        this.switch_text = "Top Viewed"
        this.list_title = "Plans"
      }
    },
    searchPlans() {
      if(this.searchInput == "")
        return
      this.isBusy = true
      let payload = {
        meta: {
          term: this.searchInput,
        }
      }

      this.$store.dispatch("plan/searchPlans", payload).then(() => {
        this.isBusy = false
      })
      .catch(() => {
        this.isBusy = false
      })
    },
    fetchPlans(current_page = 1) {
      this.isBusy = true

      let payload = {
        meta: {
          page: current_page,
          paginate: this.paginate,
        },
      }
      if (this.city_id !== '') payload = {...payload, meta: {...payload.meta, city_id: this.city_id}}
      this.$store.dispatch("plan/fetchPlans", payload)
      .then(() => {
        this.isBusy = false
      })
      .catch(() => {
        this.isBusy = false
      })
    },
    downloadUrl(data) {
      return "http://test-api.mokhatat.com/storage/"+data.relative_url
    },
    fetchCities() {
      this.isBusy = true

      const payload = {
        meta: {
          page: 1,
          paginate: 200
        },
      }
      this.$store.dispatch("city/fetchCities", payload)
    },
    paginateChange: _.debounce(function() {
      if (this.paginate > 0 && this.paginate != "")
        this.fetchPlans()
    }, 500),
  },
  created() {
    this.fetchPlans()
    this.fetchCities()
 },
}
</script>
